<!--  -->
<template>
 <div class="register">
     <div class="register-top">
         <div class="top-l">
             <img src="../../assets/images/register/logo.png" alt="">
             <span>爱玩就上爱租号</span>
         </div>
         <div class="top-r">
             <ul>
                 <li @click="$router.push({path:'/home'})">首页</li>
                 <li>用户注册</li>
                 <li @click="$router.push({path:'/center'})">帮助中心</li>
             </ul>
         </div>
     </div>
     <div class="register-center">
         <div class="cards">
             <el-card class="boxs-card">
                 <h2>注册</h2>
                <el-form ref="form" :model="form">
                         <el-input
                            placeholder="请输入手机号"
                            prefix-icon="el-icon-mobile-phone"
                            v-model="form.phone"
                            >
                            <i slot="suffix" class="el-icon-error" @click="showPwd"></i>
                        </el-input>
                        <el-input
                            placeholder="请输入验证码"
                            prefix-icon="el-icon-chat-dot-square"
                            v-model="form.code"
                            > 
                        </el-input>
                        <el-input
                            placeholder="请设置密码"
                            prefix-icon="el-icon-lock"
                            type="password"
                            v-model="form.password"
                            @keyup.enter.native="register"
                           >
                        </el-input>
                         <el-button type="primary" size="mini" plain round class="buttom-code"  @click.stop="getCode" :disabled="disabled">{{Registermessage}}</el-button>
                </el-form>
                <el-checkbox v-model="checked" size="mini">我已阅读并同意</el-checkbox> <router-link tag="span"  :to="{name: 'service'}"  :class="{comActive :checked}">《平台服务协议》和</router-link>
                  <router-link tag="span"  :to="{name: 'private'}" :class="{comActive :checked}">《隐私政策》</router-link>
                <div class="card-btn">
                     <el-button type="primary" round size="medium" @click="register">   注册   </el-button>
                </div>
                <p v-show="false">其它登录方式</p>
                <div class="card-bottom">
                    <div class="card-left" v-show="false">
                        <img src="../../assets/images/register/qq2.png" alt="">
                        <img src="../../assets/images/register/wx2.png" alt="">
                        <img src="../../assets/images/register/wb2.png" alt="">
                    </div>
                    <div class="card-right active" @click="$router.push({path: '/login'})">
                        <span class="card-spen">立即登录</span>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </el-card>
         </div>
        <div class="register-bottom">
            网络文化经营许可证：鄂网文【2020】8888-088号 网站备案号：鄂A1-20200909-1 增值业务许可证：鄂A1-20200909-1 鄂公网安备：12345678910
        </div>
         <div class="bj" v-if="yp">
              <yp @onSuccess="YpSuccess" ></yp>
          </div>
     </div>
    
 </div>
</template>
 
<script>
import Yp from '../../components/Yp'

export default {
 name: 'register',
 data () {
 return {
     tokens: '',
     yp:false,
      barSize:{width:'100%',height:'35px'},
     form:{
        phone:'',
        code:'',
        password:'',
     },
     disabled:false,
     Registermessage:'获取验证码',
     checked:""
   }
  },
  components: {
    Yp
  },
  methods:{
    // 公共url方法
    getCommpara() {
      const commpara = this.$route.params;
      return commpara;
    },
    // 清除按钮
      showPwd() {
          this.form.phone = ''
          this.form.code = ''
          this.form.password = ''
      },
    //   注册事件
      register() {
        let params = new URLSearchParams(location.search);
        let channel_id =params.get("channel_id")  || null
        var that = this;
        let rule = /^1[3|4|5|7|8][0-9]{9}$/;
        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
        if(that.form.code.length < 4){
            that.$message.warning('请输入正确的验证码');return;
        }
        if(!reg.test(that.form.password)){
            that.$message.warning('密码至少包含 数字和英文，长度6-20');return;
        }
        if(!that.checked){
            that.$message.warning('请先点击同意条款');return;
        }
        let commpara = that.getCommpara()
    
        that.$post('api/user/userReg', {phonenum:that.form.phone,vcode:that.form.code,password:that.form.password,reg_type:1,channel_id:channel_id}, commpara).then(res => {
            console.log(res,'resss');
            if (res.code == 0) {
            that.$message('注册成功');
            setTimeout(() => {
                that.$router.push('/login');
            }, 1000);
            }else{
            that.$message(res.message);
            }
        })
      },

    // 获取验证码
    getCode() {
      let that = this
      let rule = /^1[3|4|5|7|8][0-9]{9}$/
      if (!rule.test(that.form.phone)){
        that.$message.warning('请填写正确手机号码');return;
      }
      this.yp = true
    },
     // 请求二维码接口
    codeM() {
      var that = this
         const commpara = that.getCommpara();
      that
        .$post("api/verify_code", { target: that.form.phone, type: 4 ,token:this.tokens }, commpara)
        .then((res) => {
          console.log(res, "ser");
          if (res.code == 0) {
            that.$message.success("发送成功");
             // 60秒倒计时
            let time = 60;
            let timer = setInterval(() => {
              if (time <= 0) {
                that.disabled = false;
                that.Registermessage = "获取验证码";
                clearInterval(timer);
              } else {
              // 将按钮设置为不可点击状态
                that.disabled = true;
                that.Registermessage = time + "s后重试";
                time--;
              }
            }, 1000);
          } else {
            that.$message.error(res.message);
          }
        });
    },
    YpSuccess(info){
        this.tokens = info.token
        this.$post('api/user/authenticate', {authenticate:info.authenticate, token:info.token}).then(res => {
        if (res.code == 0) {
          this.yp = false
          this.codeM()
        }else{
          this.$message.error("请刷新页面");
        }
      })
    },

  }
}
</script>
 
<style lang='scss' >
 .register{
     width: 100%;
     height: 86px;
     .register-top{
         width: 1200px;
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin: 0 auto;
         .top-l{
             display: flex;
             align-items: center;
             img{
                width: 182px;
                height:45px;
             }
             span{            
                font-size: 13px;
                color: #83899D;
                margin-left: 13px;
             }
         }
         .top-r{
             ul{
                 display: flex;
                 align-items: center;
                 li{   
                   font-size: 14px;
                   color: #83899D;
                   margin-left: 29px;
                   cursor: pointer;
                 }
             }
         }
     }
     .register-center{
         width: 100%;
         height: 868px;
         background:url(../../assets/images/register/bj.png) no-repeat center;
         background-size: 100% 100%;
         position: relative;
         .register-bottom{
            width: 100%;
            height: 40px;
            text-align: center;
            line-height: 45px;
            font-size: 12px;
            color: #989AA2;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .cards{
           height: 100%;
           width: 1200px;
           margin: 0 auto;
           position: relative;
           .boxs-card{
               position: absolute;
               top: 150px;
               right:70px ;
               width: 370px;
               height: 460px;
               .el-card__body {
                    padding: 0px 38px;
                    h2{
                        margin: 49px 0 20px;
                    }
                    .el-input{
                        margin-bottom: 16px;
                        border-radius: 12px;
                        .el-icon-error{
                            margin: 14px 7px 0 0;
                        }
                    }
                    .buttom-code{
                        position: absolute;
                        top: 32.5%;
                        right: 47px;
                    }
                    .card-btn{
                        width: 100%;
                        height: 73px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                    p{
                      font-size: 14px;  
                      color: #A1A6B7;
                    }
                    .card-bottom{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 20px 0 0;
                        .card-left{
                            img{
                                width: 32px;
                                height: 32px;
                                cursor: pointer;
                            }
                        }
                        .card-right{
                            cursor: pointer;
                            color: #010101;
                             .card-spen{
                                font-size: 14px;  
                            }
                        }
                    }
                }
           }
        }
     }  
 }
 .el-checkbox__label {
    display: inline-block;
    padding-left: 10px;
    line-height: 19px;
    font-size: 10px;
}
.el-button--medium.is-round {
    padding: 10px 60px;
}
.active{
    color: #3C7EFE !important;
}
.comActive{
    color: #3C7EFE;
    font-size: 12px;
}
.bj {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
</style>